import React from "react";
import Layout from "../components/layout";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link, useIntl } from "gatsby-plugin-react-intl";
import Image from "../components/image";

const Artist = ({ pageContext }) => {
  const intl = useIntl();
  const artist = pageContext.artist;
  const info = artist.artist;

  const image = info.photo ? getImage(info.photo.localFile) : null;

  return (
    <Layout>
      {/* <div className="absolute z-[-2]  h-[100vh] top-[-88px] left-[-20px]  w-[calc(100%+20px)]">
        <Image
          src="kapusta.png"
          className="w-full h-full"
          objectFit="cover"
          objectPosition="bottom center"
          alt=""
        />
      </div>
      <div className="absolute z-[-1] top-[calc(100vh-248px)] w-[calc(100%+20px)] h-40 from-transparent to-utdf bg-gradient-to-b left-[-20px] " /> */}

      <main className="overflow-hidden">
        <div className="max-w-2xl px-4 mx-auto">
          <div className="max-w-xs mx-auto my-12 overflow-hidden rounded-md">
            <GatsbyImage
              className="w-full max-w-xs max-h-80"
              image={image}
              alt={artist.title}
            />
          </div>

          <h1 className="flex items-start gap-4 big-heading">
            {" "}
            {artist.title.split(" LIVE")[0]}{" "}
            {artist.title.split(" LIVE").length > 1 ? (
              <span className="mt-1 text-base italic font-normal font-editorial sm:text-xl">
                LIVE
              </span>
            ) : null}{" "}
          </h1>

          <p className="mb-8 font-editorial text-utdf-secondary">
            {info?.festivaldate && info?.festivaldate[0]?.title}
          </p>
          {/* </p>
            {intl.locale === "pl"
              ? info.scene[0].title
              : info.scene[0].stages.titleEn}
          </p> */}

          <div className="max-w-2xl px-4 mx-auto mb-8 prose-lg prose-invert text-utdf-text">
            {intl.locale === "pl" ? (
              <div dangerouslySetInnerHTML={{ __html: info.description }} />
            ) : intl.locale === "ua" ? (
              <div dangerouslySetInnerHTML={{ __html: info.descriptionUa }} />
            ) : (
              <div dangerouslySetInnerHTML={{ __html: info.descriptionEn }} />
            )}
          </div>

          {info.www ||
          info.bandcamp ||
          info.facebook ||
          info.instagram ||
          info.soundcloud ? (
            <section className="mb-8">
              <h2 className="small-heading text-utdf-secondary">
                {intl.formatMessage({ id: "Online" })}
              </h2>
              <ul className="text-utdf-secondary">
                {info.www && (
                  <li className="flex justify-between gap-2 mb-2">
                    {info.www.split(" ").length > 1 ? (
                      info.www.split(" ").map((i, key) => {
                        return (
                          <a
                            href={i}
                            title={`WWW ${artist.title}`}
                            target="_blank"
                            rel="noreferrer"
                            className="hover:underline"
                            key={key}
                          >
                            WWW
                          </a>
                        );
                      })
                    ) : (
                      <a
                        href={info.www}
                        title={`WWW ${artist.title}`}
                        target="_blank"
                        rel="noreferrer"
                        className="hover:underline"
                      >
                        WWW
                      </a>
                    )}
                  </li>
                )}
                {info.bandcamp && (
                  <li className="flex justify-between gap-2 mb-2">
                    {info.bandcamp.split(" ").length > 1 ? (
                      info.bandcamp.split(" ").map((i, key) => {
                        return (
                          <a
                            href={i}
                            title={`Bandcamp ${artist.title}`}
                            target="_blank"
                            rel="noreferrer"
                            className="hover:underline"
                            key={key}
                          >
                            Bandcamp
                          </a>
                        );
                      })
                    ) : (
                      <a
                        href={info.bandcamp}
                        title={`Bandcamp ${artist.title}`}
                        target="_blank"
                        rel="noreferrer"
                        className="hover:underline"
                      >
                        Bandcamp
                      </a>
                    )}
                  </li>
                )}
                {info.facebook && (
                  <li className="flex justify-between gap-2 mb-2">
                    {info.facebook.split(" ").length > 1 ? (
                      info.facebook.split(" ").map((i, key) => {
                        return (
                          <a
                            href={i}
                            title={`Facebook ${artist.title}`}
                            target="_blank"
                            rel="noreferrer"
                            className="hover:underline"
                            key={key}
                          >
                            Facebook
                          </a>
                        );
                      })
                    ) : (
                      <a
                        href={info.facebook}
                        title={`Facebook ${artist.title}`}
                        target="_blank"
                        rel="noreferrer"
                        className="hover:underline"
                      >
                        Facebook
                      </a>
                    )}
                  </li>
                )}
                {info.instagram && (
                  <li className="flex justify-between gap-2 mb-2">
                    {info.instagram.split(" ").length > 1 ? (
                      info.instagram.split(" ").map((i, key) => {
                        return i.length > 0 ? (
                          <a
                            href={i}
                            title={`Instagram ${artist.title}`}
                            target="_blank"
                            rel="noreferrer"
                            className="hover:underline"
                            key={key}
                          >
                            Instagram
                          </a>
                        ) : null;
                      })
                    ) : (
                      <a
                        href={info.instagram}
                        title={`Instagram ${artist.title}`}
                        target="_blank"
                        rel="noreferrer"
                        className="hover:underline"
                      >
                        Instagram
                      </a>
                    )}
                  </li>
                )}
                {info.soundcloud && (
                  <li className="flex justify-between gap-2 mb-2">
                    {info.soundcloud.split(" ").length > 1 ? (
                      info.soundcloud.split(" ").map((i, key) => {
                        return i.length > 0 ? (
                          <a
                            href={i}
                            title={`Soundcloud ${artist.title}`}
                            target="_blank"
                            rel="noreferrer"
                            className="hover:underline"
                            key={key}
                          >
                            Soundcloud
                          </a>
                        ) : null;
                      })
                    ) : (
                      <a
                        href={info.soundcloud}
                        title={`Soundcloud ${artist.title}`}
                        target="_blank"
                        rel="noreferrer"
                        className="hover:underline"
                      >
                        Soundcloud
                      </a>
                    )}
                  </li>
                )}
              </ul>
            </section>
          ) : null}
        </div>
        <div className="flex justify-between max-w-2xl px-4 py-8 mx-auto text-utdf-secondary">
          <Link className="text-2xl font-gambetta" to="/artists">
            ← {intl.formatMessage({ id: "Back" })}
          </Link>
          <a
            className="text-2xl font-gambetta"
            target="_blank"
            rel="noreferrer"
            href="https://www.biletomat.pl/bilety/up-to-date-festival-2024/"
          >
            {intl.formatMessage({ id: "Tickets" })} →
          </a>{" "}
        </div>
      </main>
    </Layout>
  );
};
export default Artist;
